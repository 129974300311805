<template>
  <div v-if="!loading">
    <Wrapper :disabled="!selectedProject || !selectedProject.active">
      <div></div>
      <div v-if="displayAppInstall" class="mb-10">
        <ProjectAppInstall :is-android="isAndroid" />
      </div>
      <div v-for="document in unsignedDocumentArray" :key="document.id">
        <UnsignedDocument :document="document" />
      </div>
      <div v-if="searchArray.length == 0" class="mb-10">
        <ProjectNewSearch />
      </div>
      <div v-if="selectedSearch && searchArray.length == 1" class="mb-10">
        <router-link :to="{
          name: 'ProjectSearch',
          params: { searchId: selectedSearch.id }
        }">
          <PropertyInformations :search="selectedSearch" />
        </router-link>
      </div>
      <div class="mb-10" v-if="proArray.length > 0 && (purchase || sale)">
        <ProjectGamification ref="gamification" />
      </div>
      <div class="mb-10" v-if="selectedProject.interaction_count.phone > 0 || selectedProject.interaction_count.email > 0 || selectedProject.interaction_count.chat > 0">
        <ProjectInteractions :has-searches="searchArray.length > 0" :interaction-count="selectedProject.interaction_count"
          :interactions="interactionArray" :professionals="proArray" />
      </div>
      <div class="mb-10" v-if="(summarySortedAds.length > 0 || isPolling)">
        <ProjectAds :ads="summarySortedAds" />
      </div>
      <div v-if="searchArray.length > 0" class="mb-10">
        <ProjectSearches :searches="searchArray" />
      </div>
      <!-- <div class="mb-10" v-if="purchase || sale">
        <ProjectSellerFollowUp />
      </div> -->
      <!-- div class="mb-10" v-if="isBuyer && !isQualified">
        <ProjectCoach />
      </div -->
      <div class="mb-10" v-if="signedDocumentArray.length > 0">
        <SignedDocuments :documents="signedDocumentArray" />
      </div>
      <div class="mb-10">
        <ProjectInsurance />
      </div>
      <ProjectBlog />
      <SettingsPhoneTimeSlots :show="showPhoneTimeSlot" />
    </Wrapper>
  </div>
  <div v-else>
    <Loading />
  </div>
</template>

<script>
import Wrapper from "@/components/Wrapper.vue";
import Loading from "@/components/Loading.vue";
import ProjectInteractions from "@/components/project/ProjectInteractions.vue";
import ProjectSearches from "@/components/project/ProjectSearches.vue";
import PropertyInformations from "@/components/property/PropertyInformations.vue";
//import ProjectCoach from "@/components/project/ProjectCoach.vue";
import ProjectBlog from "@/components/project/ProjectBlog.vue";
import ProjectGamification from "@/components/project/ProjectGamification.vue";
import SettingsPhoneTimeSlots from "@/components/settings/SettingsPhoneTimeSlots.vue";
import ProjectAppInstall from "@/components/project/ProjectAppInstall.vue";
import ProjectNewSearch from "@/components/project/ProjectNewSearch.vue";
import ProjectAds from "@/components/project/ProjectAds.vue";
import UnsignedDocument from "@/components/user/UnsignedDocument.vue";
import SignedDocuments from "@/components/user/SignedDocuments.vue";
import PlatformDetection from "@/platformDetection";
import ProjectInsurance from "@/components/project/ProjectInsurance.vue";

import { mapState, mapActions, mapGetters } from "vuex";
// import ProjectSellerFollowUp from "@/components/project/ProjectSellerFollowUp.vue";

export default {
  name: "ProjectSummary",

  components: {
    Wrapper,
    Loading,
    ProjectInteractions,
    ProjectSearches,
    PropertyInformations,
    //ProjectCoach,
    ProjectBlog,
    ProjectGamification,
    SettingsPhoneTimeSlots,
    ProjectAppInstall,
    ProjectNewSearch,
    //ProjectSellerFollowUp,
    ProjectAds,
    UnsignedDocument,
    SignedDocuments,
    ProjectInsurance
  },

  data: function () {
    return {
      loading: true,
      showPhoneTimeSlot: false
    };
  },

  computed: {
    ...mapState("project", ["selectedProject", "needProjectDataRefresh"]),
    ...mapState("search", ["searchArray", "selectedSearch"]),
    ...mapState("interaction", ["interactionArray"]),
    ...mapState("user", ["user", "isBuyer", "hasPropertyForSale", "isQualified", "signedDocumentArray", "unsignedDocumentArray"]),
    ...mapState("pro", ["proArray"]),
    ...mapState("progress", ["purchase", "sale"]),
    ...mapState("ad", ["adArray"]),
    ...mapGetters("ad", ["summarySortedAds", 'sortedAds', 'isPolling']),


    displayAppInstall: function () {
      return (
        (PlatformDetection.isAndroid() || PlatformDetection.isIOS()) &&
        !PlatformDetection.isMobileApp()
      );
    },

    isAndroid: function () {
      return PlatformDetection.isAndroid();
    }
  },

  methods: {
    ...mapActions("project", [
      "fetchInteractions",
      "fetchSearches",
      "fetchSale",
      "fetchChats",
      "fetchPros",
      "fetchProgresses",
    ]),
    ...mapActions("user", ["fetchMe", "fetchDocuments"]),
    needUserPhoneTimeSlots() {
      if (this.user.phone.length > 0 && this.user.phone_time_slots == null) {
        this.$nextTick(() => {
          this.showPhoneTimeSlot = true;
        });
      }
    }
  },

  async mounted() {
    try {
      if (this.needProjectDataRefresh) {
        this.loading = false;
        await this.fetchMe();
        await this.$store.dispatch("multipleFetch", [
          { fn: this.fetchSearches, body: { id: this.selectedProject.id } },
          { fn: this.fetchInteractions, body: { id: this.selectedProject.id } },
          { fn: this.fetchChats, body: { id: this.selectedProject.id } },
          { fn: this.fetchPros, body: { id: this.selectedProject.id } },
          { fn: this.fetchSale, body: { id: this.selectedProject.id } },
          { fn: this.fetchProgresses, body: { id: this.selectedProject.id } },
          { fn: this.fetchDocuments, body: { id: this.user.id } },
        ]);
      }
      this.$store.commit("project/NEED_PROJECT_DATA_REFRESH", true);
    } catch (error) {
      console.log(error);
      this.loading = false;
    } finally {
      this.loading = false;
      setTimeout(() => {
        this.needUserPhoneTimeSlots();
      }, 1000);
    }
  }
};
</script>
